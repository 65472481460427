import { makeStyles } from '@material-ui/core/styles';

import backgroundGif from '../images/backgroundGif.gif';

export const useStyles = makeStyles(theme => ({
    '@keyframes slidein-left': {
        '0%': {
            transform: 'translateX(-7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    '@keyframes slidein-right': {
        '0%': {
            transform: 'translateX(7%)',
            opacity: 0,
        },
        '100%': {
            transform: 'translateX(0)',
            opacity: 1,
        },
    },
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(24, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(10, 14),
            height: 'auto',
        },
        '@media (max-width: 1366px)': {
            minHeight: '100vh',
        },
        '@media (max-width: 1024px)': {
            padding: theme.spacing(10),
            height: '100vh',
        },

        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.common.black,
            height: 'auto',
            paddingBottom: theme.spacing(5),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(10, 5, 5, 5),
        },
    },
    gif: {
        backgroundImage: `url(${ backgroundGif })`,
        [theme.breakpoints.down('sm')]: {
            background: 'none',
        },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100vw 110vh',
        backgroundAttachment: 'fixed',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        animation: '$slidein-right 0.3s ease backwards',
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
    },
    gridAnimation: {
        animation: '$slidein-left 0.3s ease backwards',
        [theme.breakpoints.down('sm')]: {
            animation: 'none',
        },
    },
    contactHeader: {
        fontSize: '5vw',
        fontWeight: 'bold',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '& .MuiOutlinedInput-root, .MuiInputBase-input': {
            borderRadius: 0,
            color: theme.palette.common.white,
            '& fieldset, &:hover fieldset, &.Mui-focused fieldset': {
                borderColor: theme.palette.common.white,
                opacity: 0.8,
            },
        },
    },
    formButtonAndMessage: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    errorMessage: {
        color: 'red',
        margin: '0 14px',
        minHeight: '24px',
        maxHeight: '24px',
    },
    successMessage: {
        color: 'white',
        margin: '0 14px',
        minHeight: '24px',
        maxHeight: '24px',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
        fontWeight: 900,
        fontSize: '18px',
        lineHeight: '21px',
        maxHeight: '24px',
        letterSpacing: '0.1em',
        '&:hover': {
            color: theme.palette.orange,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    descriptionBox: {
        maxWidth: '75%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    link: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    footer: {
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(0, 16),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 10),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 10),
        },
        fontSize: '16px',
        fontWeight: 415,
        lineHeight: '20px',
        letterSpacing: '0.05em',
        transition: 'height .3s ease',
        color: theme.palette.common.black,
        backgroundColor: theme.palette.orange,
        height: '60px',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            lineHeight: '16px',
            '&:hover': {
                fontSize: '14px',
                lineHeight: '17px',
            },
        },
    },
    footerContainer: {
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'block',
        width: '100vw',
    },
    footerContainerForForm: {
        display: 'block',
        position: "fixed",
        width: '100%',
        bottom: 0,
        right: 0,
    },
    hiddenText: {
        display: 'flex',
        justifyContent: 'flex-end',
        opacity: 0,
        transition: 'opacity 1s, transform 1.3s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
    },
    hiddenForm: {
        opacity: 0,
        transition: 'opacity 1s, transform 1.3s',
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
    },
    mobileFooter: {
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.01em',
        fontWeight: 600,
        color: theme.palette.orange,
        textDecoration: "none"
    },
    linkLogo: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '16vh',
    },
    logoOke: {
        width: '70%',
        height: '8vh',
        [theme.breakpoints.down('xs')]: {
            height: '5vh',
            width: '100%',
        },
    },
    okeLogo: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    okeLink: {
        color: theme.palette.lightBlue,
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    footerItemWrapper: {
        paddingLeft: 10,
        display: 'flex',
        alignItems: 'center',
    },
    desktopLink:{
        textDecoration: "none",
        color: "black"
    }
}));
